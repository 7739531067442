.cardWrapper {
    display: flex;
    max-width: 320px;
    // background-color: white;
    flex-direction: column;
    border-radius: 0.5em;
    -webkit-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    box-shadow: 0px 29px 38px -15px rgba(0,0,0,0.43);
    background-color: #f4f6fe;
    padding: 20px 40px;
    .imgWrapper {
        width: 100%;
        padding: 10px;
        img {
            width: auto;
            height: 400px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            object-fit: cover;
        }
    }
    h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        margin-top: 1em;
    }
    p{
        text-align: justify;
        font-size: 18px;
        padding: 0 20px;
    }
}