@import '../../../colors';

.serviceBlockContainer {
    overflow: hidden;
  }

.desc {
    word-break: break-word;
}

.bgcolor {
    color: $color-title !important;
}