@import "../../../colors";

.valuesImage {
  width: 100%;
}

.valueBlockContainer {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: $color-para;
  overflow: hidden;
}
.contentBody {
  margin-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  // align-items: center;
  font-weight: 500;
  text-align: start;
  color: $color-primary;

  button {
    width: 40%;
  }
}

.contentHeading {
  color: $color-title;
  font-weight: 600;
  font-size: 30px;
  margin-top: 30px;

  font-family: 'Poiret One', sans-serif !important;
  font-weight: 600px;
}

.row {
  margin-top: 10px !important;
}

@media only screen and (max-width: 950px) {
  .row {
    flex-direction: row !important;
  }
}
