@import '../../colors';
.cardStyle {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.image {
    width: 83%;
    margin: 20px;
}
.rowStyle {
    max-width: 100%;
    margin: 0;
    padding: 30px;
}
.content  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-secondary !important;
    // font-family: 'Open Sans', sans-serif;
    padding: 20px 0px;
    // width: 90%
    p{
        text-align: justify;
    }

   
}

.commanderprofile {
    background-color: #052640;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .content  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff !important;
        // font-family: 'Open Sans', sans-serif;
        padding: 20px 20px;
        // width: 90%
        p{
            text-align: justify;
        }

        

    }
    .image {
        width: 60% !important;
        margin: 20px;
    }
}