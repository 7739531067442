@import '../../colors';

.leftContainer, .rightContainer {
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  height: 300px;
  .imgContainer {
    height: 100%;
    width: 50%;
    background-position: center;
    background-size: contain;
  }
}
.detailsContainer {
  margin-left: 50px;
  padding:  0 20px;
  text-align: left;
  position: relative;
  .title {
    font-size: 50px;
    // font-family: 'ui-rounded';
    color: $color-title;
    font-weight: 700;
  }
  .description {
    font-size: 30px;
    // font-family: 'ui-rounded;'
  }
}

.buttonContainer {
  position: absolute;
  bottom: 10px;
}

.leftContainer {
}

.rightContainer {
}

.featureContainer {
}
