@import "../../colors";
.homeContainer {
  overflow-x: hidden;
  // font-family: "Anton", sans-serif;
  background: url("../../assets/header-contactus.jpeg") no-repeat right center,
  linear-gradient(to right, $color-primary, $color-gradient1);
  height: 812px;
  // background: linear-gradient(to right, $color-primary, $color-gradient);
  background-size: cover;
  animation: gradient 15s ease infinite;
  background-blend-mode: multiply;
  // min-height: calc(max-content + 500px) !important;
  position: relative;
  .backgroundImageContainer {
    height: 100% !important;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .maintag {
    z-index: 2;
    width: 750px;
    text-align: left;
    margin-left: 200px;
    font-size: 60px;
    color: white;
    margin-top: 135px;
    font-family: "Anton", sans-serif;
  }
  .visionContainer {
    margin-left: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    width: 50%;
    font-size: 18px;
    color: white;
    text-align: left;
    .label {
      font-weight: 700;
      line-height: 25px;
      // width: 250px;
      font-size: 24px;
      // text-decoration: underline;
    }
    .visionDesc {
      padding-left: 15px !important;
    }
  }
  .statContainer {
    overflow-x: scroll;
    margin-left: auto;
    margin-top: 10vh;
    background-color: rgba(71, 75, 108, 0.52);
    border-radius: 50px 0 0 0;
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    padding: 15px 35px 15px 50px;
    text-align: center;
    .statItem {
      position: relative;
      color: white;
      margin: 0 10px;
      .statValue {
        font-size: 40px;
        font-weight: 700;
      }
      .statDesc {
        font-size: 20px;
        width: max-content;
      }
    }
  }
}
.featureContainer {
  margin: 70px 0;
}
.careerContainer {
  margin: 30px 0;
}
.managementContainer {
  margin: 70px 0;
}
.statContainer::-webkit-scrollbar {
  display: none;
}

.statContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (min-width: 950px) and (max-width: 1180px) {
  .statContainer {
    .statItem {
      .statValue {
        font-size: 30px !important;
      }
      .statDesc {
        font-size: 14px !important;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .maintag {
    width: 60% !important;
    font-size: 40px !important;
    margin-left: 0 !important;
    padding: 0 20%;
    display: flex;
    // justify-content: center;
  }

  .visionContainer {
    width: 95% !important;
    margin-left: 0 !important;
    font-size: 13px !important;
    padding: 0 20%;
    .label {
      width: 150px;
      margin-right: 15px !important;
    }
  }

  .backgroundImageContainer {
    background-color: rgba(0, 0, 0, 0.3);
    background-position-x: right !important ;
    background-size: contain !important;
    height: 100% !important;
  }

  .statContainer {
    border-radius: 20px 0 0 0 !important;
    width: 90% !important;
    padding: 5px;
    margin-top: 5vh !important;
    margin-left: auto;
    .statValue {
      font-size: 20px !important;
    }
    .statDesc {
      font-size: 10px !important;
    }
    .lead {
      font-size: 14px !important;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
