@import '../../colors';

.buttonContainer {
  height: 55px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: $color-title;
  border-radius: 55px;
  border-width: 6px;
  color: $color-title;
  font-weight: 700;
  padding-bottom: 3px;
  padding: 0 30px 3px 30px;
  margin: 20px 0;
  cursor: pointer;
}
