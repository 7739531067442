body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif !important; */
  /* font-family: lato !important; */
  font-family: "DM Sans", sans-serif !important;
  
  /* font-family: 'Montserrat', sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a4a63 !important;
}
.bg-white {
  background-color: #fff;
}
/* different icons for list */
.list1 {
  list-style-type: '▶';
  font-size: 18px;
  font-weight: 500;
  /* color: #fff; */
}
.list2 {
  list-style-type: '⇨';
  font-size: 16px;
  font-weight: 400;
  /* color: #fff; */
}
.list3 {
  list-style-type: '★';
  font-size: 14px;
  font-weight: 300;
  /* color: #fff; */
}
.list4 {
  list-style-type: '☞';
  font-size: 12px;
  font-weight: 200;
  /* color: #fff; */
}
.crl-white {
  color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.textAlignLeft {
  text-align: left;
}
.red-title {
  color: #bd1f13;
  font-weight: 700 !important;
  font-family: 'Poiret One', sans-serif !important;
  text-transform: uppercase;
}
.blue-title {
  color: #012d5a;
}
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-align-left {
  text-align: left;
}
.mainHeading {
  font-size: 55px;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 30px;
  /* color: #9a1c12; */
  font-weight: 700;
  font-family: 'Poiret One', sans-serif !important;
}
.heading2 {
  font-size: 35px;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 30px;
}
.subHeading {
  font-size: 30px;
  text-transform: capitalize;
  padding: 10px;
  margin-top: 30px;

  font-family: 'Poiret One', sans-serif !important;
  font-weight: 600px;
}
.custom-accordion {
  width: 80%;
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
}
.p-20 {
  padding: 20px;
}
.container-wrapper {
  text-align: center;
  /* color: var(--color); */
  color: #2a4a63;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(./assets/aboutusbg.jpg);
  background-size: cover;
  background-position: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;
}

.container-wrapper-contact {
  text-align: center;
  color: var(--color);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(./assets/aboutusbg.jpg);
  /* background-image: url(./assets/handshake.jpg); */
  background-size: cover;
  background-position: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;
}

.container-wrapper-clients {
  text-align: center;
  color: var(--color);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-image: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(./assets/aboutusbg.jpg);
  /* background-image: url(./assets/handshake.jpg); */
  background-size: cover;
  background-position: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;
}
.height-200 {
  height: 200px;
}
.max-w-1320 {
  max-width: 1320px;
}
.panelWrapper {
  padding: 25px 20px;
}
.mar-b-100 {
  margin-bottom: 100px;
}
.mar-t-100 {
  margin-top: 100px !important;
}

.padding-tb-100 {
  padding-top: 100px!important;
  padding-bottom: 100px!important;
}

.gap-20 {
  gap: 20px;
}
.width-100 {
  width: 100%;
}
.catouselWrapper {
  height: 500px;
  z-index: 5;
  width: 100%;
  background-color: black;
}
.carousel-inner {
  height: 100%;
}
.carousel-item {
  height: 100%;
}
#carouselExampleControls {
  height: 100%;
}
.img {
  width: 90%;
  height: 200px;
  background-color: lightgrey;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  border-radius: 0.3em;
}

.item-aboutus {
  display: flex;
  background-color: #f4f6fe;
  /* background-color: #052640; */
  /* color: #fff; */
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  /* border-radius: .5em; */
  -webkit-box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 29px 38px -15px rgba(0, 0, 0, 0.43);
}
@media only screen and (max-width: 600px) {
  .item-aboutus {
    font-size: 14px !important;
  }
  .subHeading {
    font-size: 22px;
  }
  .custom-accordion {
    width: 100%;
  }
}
ul {
  color: #012d5a;
}
.text-wrapper {
  color: black;
}

/*** Section Title ***/

#contact .form-control {
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  background: none;
}
.section-title {
  position: relative;
  display: inline-block;
  color: #fff;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

.overlay {
  /* position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);  */
  /* Black see-through */
  /* color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center; */

  background-color: #fff;
}

/* .overlay-aboutus {
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
} */

.text-primary-color {
  color: #2a4a63;
}

/*** Section Title  end***/

.dropdown-menu {
  background-color: #fff !important;
  z-index: 1 !important;
  width: fit-content !important;
  position: relative !important;
}

.aboutus_section {
  background-image:  linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.5)),url(./assets/aerial-view-business-team.jpg);
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  text-align: center;

}
.aboutus_content {
  width: 80%;
  margin: auto;
}

.readMore {
  background-color:  #9a1c12 !important;
  color: #fff !important;
  transition: height 0.8s linear;
}
.memorialContainer {
  width: 40%;
  margin-left :auto !important;
  margin-right: auto !important;
}

.memorialContainer1 {
  background-color: #052640;
  color: #fff;
  padding-top: 30px;
  /* padding-bottom: 10px; */
  text-transform: uppercase;
}

.headingstyle {
  /* font-family: Poiret One, sans-serif !important; */
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.black-text {
  color: #6c757d;
}