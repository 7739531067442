@import '../../colors';

.contentHeading {
    color: $color-title !important;
    font-weight: 700;
    font-size: 35px;
  }
.layoutWrapper {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
        width: 95%;
    }
    @media only screen and (min-width: 767px) {
        width: 85%;
    }

    padding: 20px;
    display: flex;
    p {
        font-size: 18px;
        text-align: justify;
    }
    .list {
        font-size: 18px;
        list-style-type: '*';
        // color: #fff;
        font-weight: 500;
        li{
            text-align: left;
            padding-left: 5px;
        }
        p{
            font-weight: 400;
        }
    }
    .readMore {
        background-color:  $color-title !important;
        color: #fff !important;
        transition: height 0.8s linear;
    }
    @keyframes hideLists {
        0% {
            opacity: 1;
            height: 100%;
            visibility: visible;
        }
        25% { 
            height: 75%;
            opacity: 0.75;
        }
        50% { 
            height: 50%;
            opacity: 0.5;
        }
        75% { 
            height: 25%;
            opacity: 0.25;
        }
        100% {
            height: 0;
            opacity: 0;
            visibility: hidden;
        }
    }
    .hideList{
        height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        animation: hideLists 0.8s linear 0s 1 backwards ;
    }
    @keyframes showLists {
        0% {
            height: 0;
            opacity: 0;
        }
        25% { 
            height: 25%;
            opacity: 0.25;
        }
        50% { 
            height: 50%;
            opacity: 0.5;
        }
        75% { 
            height: 75%;
            opacity: 0.75;
        }
        100% {
            height: auto;
            opacity: 1;
            visibility: visible;
            overflow: auto;
        }
    }
    .showList {
        animation: showLists 1s linear 0s 1 forwards  ;
    }
}

@media only screen and (max-width: 600px) {
    .layoutWrapper {
        p {
            font-size: 16px;
        }
    }
}