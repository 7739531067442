@import '../../../colors';
.headerContainer {
  // height: 140px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  background: $color-primary;
  // padding: 35px;
  align-items: center;
  .logoContainer {
    // width: 300px;
    // height: 100px;
    // padding: 5px;
    .logo {
      width: 280px;
      height: 75px;
    }
  }
  .menuWrapper {
    // height: 100px;
  }

  .menuIconContainer {
    display: none;
  }
  .listMenuContainer {
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .headerContainer {
    // height: 100px !important;
    height: 110px !important;

    .logoContainer {
      width: 150px;
      // height: 50px;
      padding: 5px;
      .logo {
        width: 200px;
        height: max-content;
      }
    }
  }
  .menuWrapper {
    display: none;
  }
  
  .listMenuContainer {
    display: block !important;
  } 
  .menuIconContainer {
    display: block !important;
    width: 20px;
    height: 20px;
    // margin-top: 15px;
    margin-right: 20px;
    cursor: pointer;
    .menuIcon {
      width: 20px;
      height: 20px;
      background-color: white;
    }
  }
}
