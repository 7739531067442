@import '../../colors';

.leftContainer {
  .titleContainer {
    text-align: left;
    padding-left: 50px;
    .title {
      left: 40px;
    }
  }
  .detailsContainer {
    background: linear-gradient(to right, #fff, $color-title);
  }
}
.rightContainer {
  .titleContainer {
    text-align: right;
    padding-right: 50px;
    .title {
      right: 40px;
    }
  }

  .detailsContainer {
    background: linear-gradient(to right, $color-title, #fff);
  }
}
.leftContainer,
.rightContainer {
  display: flex;
  justify-content: space-between;
  margin: 100px 0;
  .imgContainer {
    width: 20%;
    background-repeat: no-repeat;
    background-position: right;
  }
  .detailsContainer {
    width: 80%;
    text-align: left;
    .titleContainer {
      background: white;
      height: 200px;
      position: relative;
      .title {
        position: absolute;
        bottom: 40px;
        font-size: 55px;
        color: $color-title;
        font-weight: 700;
      }
    }
    .descriptionContainer {
      padding: 50px 50px 0 40px;
      .description {
        font-size: 40px;
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .buttonContainer {
          .memberButton {
            height: 55px;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-style: solid;
            border-color: $color-title;
            border-radius: 55px;
            border-width: 6px;
            color: $color-title;
            font-weight: 700;
            padding-bottom: 3px;
            padding: 0 30px 3px 30px;
            margin: 20px 0;
            cursor: pointer;
          }
        }
        .designation {
          font-size: 45px;
          font-weight: 700; 
          font-style: italic;
          color: white;
          max-width: 45%;
          padding-bottom: 10px;
        }
      }
    }
  }
}
