@import '../../colors';

.menuContainer {
  display: flex;
  justify-content: space-evenly;
  transition: all 0.5s ease-in-out;
  z-index: 2;

  .menuItem {
    padding: 15px 20px;
    font-size: 22px;
    cursor: pointer !important;
    color: #fff;
    font-family: 'Poiret One', sans-serif !important;
    // font-family: 'ui-rounded';
  }
  .selectedMenuItem {
    font-size: 24px;
    padding: 15px 20px;
    color: #bd1f13;
    font-weight: 900;
    cursor: pointer;
    // text-decoration: underline;
    // font-family: 'ui-rounded;';
    font-family: 'Poiret One', sans-serif !important;
  }
  .selectedMenuItem:hover,
  .menuItem:hover {
    font-weight: 700;
    color: #2a4a63;
  }
}

.closeIconContainer {
    display: none;
    right: 55px;
    top: 30px;
    position:absolute;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    cursor: pointer;
    .closeIcon {
      width: 20px;
      height: 20px;
    }
  }


@media only screen and (max-width: 950px) {

    .closeIconContainer {
        display: block;
    }
    .menuContainer {
        position: absolute;
        width: 100%;
        padding: 50px 60px 20px 10px;
        right: 0;
        top: 0px;
        display: block;
        background-color: #fff;
        .menuItem {
           color: $color-para;
           text-align: left;
        }
        .selectedMenuItem {             
           color: $color-para;
           text-align: left;
        }
    }
}

