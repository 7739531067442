@import '../../colors';

.container {
  height: 480px;
  width: 100%;
  background-position: center;
  background-size: fill;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .overLay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
  }
  .careerButton {
    height: 55px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #fff;
    border-radius: 22px;
    border-width: 6px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 3px;
    padding: 0 30px 3px 30px;
    margin: 20px 0;
    cursor: pointer;
    z-index: 2;
  }
}
