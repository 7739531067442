@import './colors';
.App {
  text-align: center;
  // font-family: "Open Sans", sans-serif;
  // font-family: lato; 
  font-family: "DM Sans", sans-serif !important;
  // font-family: 'Montserrat', sans-serif !important;
  // background-color: $color-primary;
}
.body {
  // font-family: "Open Sans", sans-serif;
  // font-family: lato !important; 
  font-family: "DM Sans", sans-serif !important;
  color: $color-secondary !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited {
  background-color: $color-secondary !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.valuesImage {
  width: 90%;
}

.valueBlockContainer {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
}

/* Team */

.team-item img {
  position: relative;
  top: 0;
  transition: 0.5s;
}

.team-item:hover img {
  top: -30px;
}

.team-item .team-text {
  position: relative;
  height: 100px;
  transition: 0.5s;
  color: #012d5a;
}

.team-item:hover .team-text {
  margin-top: -60px;
  height: 160px;
}

.team-item .team-text .team-social {
  opacity: 0;
  transition: 0.5s;
}

.team-item:hover .team-text .team-social {
  opacity: 1;
}

.team-item .team-social .btn {
  display: inline-flex;
  color: var(--primary);
  background: #ffffff;
  border-radius: 40px;
}

.team-item .team-social .btn:hover {
  color: #ffffff;
  background: var(--primary);
}

.card-people-img {
  /* height: 340px; */
  max-height: 380px;
}

/*** clients ***/

#projects {
  color: #2a4a63;
}

.project-card-container {
  padding: 20px;
  background: none !important;
}

.project-card {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  // height: 300px;
  min-height: 300px;
  padding: 20px;
  // width: 75%;
  color: #2a4a63;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 7px;
  
  p {
    width: 90%;
    font-weight: 700;
  }
}

.project-img {
  height: 170px;
  /* width: 240px; */
  margin-bottom: 15px;
  box-shadow: none;
  padding: 10px;
  cursor: pointer;
}
.project-desc :hover,
.project-img :hover {
  box-shadow: 0 !important;
}
.project-desc {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.address {
  font-size: 14px;
  padding: 5px;
}
/** clients end ***/

.profile-pointers {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  list-style-type: square;
  color: #395e81 !important; 
}

.profile-pointers li {
  margin-top: 10px;
  color: #395e81 !important; 
}

profile-pointers h5{
  margin-top: 10px;
  color: #395e81 !important; 
  font-weight: 700;
  
}

.btn-blue {
  background-color: #395e81 !important; 
  border: none !important;
}