@import '../../colors';
.accordionWrapper{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    transition: all 0.5s ease-in-out;
    margin-bottom: 20px;
    .header {
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // margin-top: 15px;
        button{
            font-size: 20px;
            font-weight: 500;
            color: $color-secondary !important;
        }
        i {
            font-size: 18px;
            color: $color-secondary !important;
        }
    }
    p {
        padding: 10px;
    }
}
@media only screen and (max-width: 600px) {
    .accordionWrapper {
        p {
            text-align: justify;
        }
        button{
            font-size: 18px !important;
        }
    }
}