@import '../../colors';

$bigScreen: 16px;
$smallScreen:16px;



.wrapper {
    ul {
        color: white
    }
    background-color: $color-primary;
    color: #fff;
    padding: 40px;
    // margin-top: 30px;
    .links {
        display: flex;
        justify-content: space-between;
        padding: 40px;
        flex-wrap: wrap;
        gap: 30px;
        .link {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            text-align: left;
            .title {
                margin-bottom: 18px;
                font-size: 18px;
                margin-left: 13px;
            }
            .linkWrapper {
                font-size: $bigScreen;
                a {
                    color: #fff;
                    text-align: left;
                    text-decoration: none;
                    display: flex;
                }
                span {
                    width: 300px;
                    display: flex;
                }
            }
        }
    }
    .companyTagline {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 30px;
        padding-bottom: 20px;
        border-bottom: 2px solid #c4c4cc;
        p{
            width: 50%;
            min-width: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: justify;
            flex-wrap: wrap;
        }
        .socialLinks {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            .socialLink {
                background-color: #fff;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: grid;
                place-items: center;
                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    .copyright {
        margin-top: 20px;
        flex-wrap: wrap;
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 600px) {
    .wrapper {
        padding: 10px;
        overflow: hidden !important;
        .links {
            padding: 10px;
            .link{
                .linkWrapper {
                    font-size: $smallScreen !important;
                }
            }
        }
        .companyTagline {
            max-width: 100%;
        }
    }
}

@media only screen and (min-width: 601px) {
    .wrapper {
        padding: 40px;
        overflow: hidden !important;
        .links {
            padding: 20px;
        }
        .companyTagline {
            max-width: 100%;
        }
    }
}